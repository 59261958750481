<template>
    <div class="goods-page">
        <div class="goods-list">
            <div v-if="dataList && dataList.length > 0" class="list-container">
                <div v-for="(item, index) in dataList" class="list-item">
                    <div class="item-wrapper">
                        <p>任务名称：{{ item.name }}</p>
                        <p class="p3" @click="toPunch(item)">查看详情</p>
                    </div>
                    <div class="item-wrapper" style="justify-content: space-between; margin-top: 15px">
                        <p class="p2 p4">规则天数：{{ item.task_day }}</p>
                        <p class="p2 p4">规则次数：{{ item.task_count }}</p>
                        <p class="p2 p4">是否循环：{{ item.is_cycle === 1 ? "是" : "否" }}</p>
                    </div>
                </div>
            </div>
            <van-empty v-else description="暂无数据" />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import usePublic from "@/hooks/usePublic";
import { GET_PATROL_LIST, GET_PATROL_UNITS_LIST } from "@/api/patrol";

let { $route, $router, $store } = usePublic();

let tabActive = ref(1);

onMounted(() => {
    getDataList();
});

// 获取列表数据
let dataList = ref([]);

let getDataList = () => {
    GET_PATROL_UNITS_LIST({
        limit: 10000
    }).then((res) => {
        dataList.value = res.data.data.list;
    });
};

// 前往打卡
let toPunch = (item) => {
    console.log(item);
    $router.push({
        name: "PatrolPunch",
        params: {
            id: item.id
        }
    });
};
</script>

<style lang="scss" scoped>
.goods-page {
    min-height: 100vh;
    padding-bottom: 60px;
    background: #f0f0f0;

    .tab-bar {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    .goods-list {
        .list-container {
            width: 100%;
            padding: 0 15px;
            background: #ffffff;

            .list-item {
                padding: 15px 0;
                border-bottom: 1px dashed #eeeeee;

                &:last-child {
                    border-bottom: none;
                }

                .item-wrapper {
                    width: 100%;
                    display: flex;
                    align-items: center;
                }

                :deep(.van-checkbox-group) {
                    width: 100%;

                    .van-checkbox {
                        .van-checkbox__label {
                            width: 100%;
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                p {
                    font-size: 12px;
                }

                .p1 {
                    width: 180px;
                    color: #000000;
                }

                .p2 {
                    color: #999999;
                }

                .p3 {
                    margin-left: auto;
                    color: #df5f0b;
                }

                .p4 {
                    margin-right: 10px;
                }
            }
        }
    }

    .goods-button {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        border-radius: 0;
    }
}
</style>
